<template>
    <div class="container-fluid">
        <b-alert :show="showError" variant="danger" class="p-1 w-100">
            <b>Error: </b> Render failed because current file is corrupted. Please upload a new file and try again!
           </b-alert>
           <div class="text-right d-md-none">
            <feather-icon   size="26" icon="ChevronDownIcon" class="cursor-pointer mb-1 mx-2" @click="()=>showDetails=!showDetails" ></feather-icon>

           </div>  
        <div class="row">     
            <div class="col-md-7 col-12">
                <invoice-viewer v-if="file" :initialDoc="file" @renderFailed="()=>showError=true" @currentPage="invoiceLoaded()"/>
                <!-- <img src="@/assets/images/app-images/invoice-temp.png"/>  -->
            </div>
            <div class="col-5 d-none d-md-block">
                <div class="bg-white p-24">
                    <invoice-details-card ref="invoiceDetail1" @download="downloadInvoice()" @backup="saveToBackup()" @send="sendInvoice()"/>
                </div>
            </div>
            <b-sidebar class="d-lg-none " v-model="showDetails" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
                <div class="p-2 d-flex align-items-center border-bottom mt-5">
                    <app-heading-2 class="mt-1"><feather-icon icon="ChevronLeftIcon" size="22" @click="()=>showDetails=false" ></feather-icon>Invoice Details</app-heading-2>
                </div>
                <div class="p-2 h-100">
                    <invoice-details-card ref="invoiceDetail" @download="downloadInvoice()" @backup="saveToBackup()" @send="sendInvoice()"/>
                </div>
            </b-sidebar>
        </div>
    </div>

</template>
<script>
import {BBadge,BButton, BAvatarGroup, BAvatar, VBTooltip, BTooltip, BAlert, BSidebar} from 'bootstrap-vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import {get_invoice,send_invoice, save_to_backup, download_invoice} from '@/apis/others/invoices'
import InvoiceViewer from '@/components/functional-components/other/invoice/InvoiceViewer.vue'
import InvoiceDetailsCard from '@/components/functional-components/other/invoice/InvoiceDetailsCard.vue'
import moment from 'moment'
export default{
    directives: {
        'b-tooltip': VBTooltip,
    },
    components:{
        BBadge,
        BButton,
        AppSimpleButton,
        BAvatarGroup, 
        BAvatar,
        InvoiceViewer,
        BTooltip,
        InvoiceDetailsCard,
        BAlert,
        BSidebar
    },
    data(){
        return{
            invoiceDetails:{
                description:'',
                amount:0,
                status:'',
                collaborators:[],
                dueDate:'',
            },
            file:'',
            loading:false,
            backupLoading:false,
            downloadLoading:false,
            colors:['light-primary','light-info','light-success','light-danger','light-secondary'],
            sendLoading:false,
            showError:false,
            showDetails:false,
        }
    },
    mounted(){
        this.getInvoice()
    },
    methods:{
        getInvoice(){
            this.loading = true
            get_invoice(this.$route.params.id).then(resp=>{
                this.$refs.invoiceDetail.invoiceDetails = resp.data
                this.$refs.invoiceDetail1.invoiceDetails = resp.data
                this.loading = false
                this.file = resp.data.attachment.fileLink
            })
        },
        getFormatedDate(date,format){
            return moment(date).format(format)
        },
        saveToBackup(){
            this.backupLoading=true;
             save_to_backup(this.$route.params.id).then(resp=>{
                this.backupLoading=false;
                this.$bvToast.toast(resp.message, {
                        title: 'Saved to backup',
                        variant: 'success',
                        solid: true,
                    })
             }).catch(error => {
                this.$bvToast.toast(error.message, {
                        title: error.error,
                        variant: 'danger',
                        solid: true,
                    })
                    this.backupLoading=false;   
             })
        },
        downloadInvoice(){
            this.downloadLoading=true;
            download_invoice(this.$route.params.id).then(resp=>{
                this.downloadLoading=false;
                this.download(resp.url)
            })
        },
        download(url){
            var link = document.createElement("a");
            // If you don't know the name or want to use
            // the webserver default set name = ''

            link.setAttribute('download', this.$route.params.invoice);
            link.href = url;
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        sendInvoice(){
            this.$refs.invoiceDetail1.sendLoading=true;
            this.$refs.invoiceDetail.sendLoading=true;
            send_invoice(this.$route.params.id).then((resp)=>{
                this.$refs.invoiceDetail.sendLoading=false;
                this.$refs.invoiceDetail1.sendLoading=false;
                this.$bvToast.toast(resp.message, {
                    title: 'Success',
                    variant:'success',
                    solid: true,
                })
                this.getInvoice()
            }).catch((err)=>{
                this.sendLoading=false;
                this.$bvToast.toast(err.message, {
                    title: err.error,
                    variant: 'danger',
                    solid: true,
                })
            })
        },
        toCapitalize(name){
            let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        },
        getcollaborators(collaborators){
            let colab=''
            collaborators.map(c=>{
                colab=`${colab}${c.email}\n`
            });
            return colab
        },
        invoiceLoaded(){
            this.$refs.invoiceDetail1.sendLoading=false;
            this.$refs.invoiceDetail.sendLoading=false;
        }
    }
}
</script>
<style scoped>
.p-24{
    padding: 24px;
    border-radius: 6px;
}

.text-simple{
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin: 8px 0px;
    color: #4B465C;
}
.heading-text{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #4B465C;
}
@media(max-width:768px){
    .b-sidebar-outer{
        z-index: 10 !important;
    }
}
</style>
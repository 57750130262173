import http from "@/utils/http";

export async function create_invoice(data){
    const req = (await http.post(`/invoice`,data));
    return req.data; 
}

export async function get_invoices_list(type,filters){
    const req = (await http.get(`/invoice/${type}/filter`, {params:filters}));
    return req.data; 
}

export async function get_invoice(id){
    const req = (await http.get(`/invoice/${id}`));
    return req.data;
}

export async function save_to_backup(id){
    const req = (await http.post(`/invoice/backup`, {invoiceId:id}));
    return req.data;
}

export async function download_invoice(id){
    const req = (await http.get(`/invoice/invoiceFile/${id}`));
    return req.data;
}

export async function send_invoice(id){
    const req = (await http.put(`/invoice/send/${id}`));
    return req.data;
}